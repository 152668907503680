/*
 * UserNotificationService service for VUE
 * Used to share user notification information across components
 *
 * usage:
 * import UserNotificationService from '$cdn/Services/Cache/UserNotificationService';
 * UserNotificationService.subscribe();
 *
 * Find other methods inside the file
 * 1. Get {offset, limit} -> Init
 * 2. Get {offset, limit} -> Load more
 * 3. Read One
 * 4. Read All
 * 5. Remove One
 * 6. Remove all
 * 7. Get Total Count -> POLL
 */

import $http from '@master/Services/HttpService';
import UserService from '@master/Services/UserService';
import { TIMEOUT } from '@master/constants';

const STATUS_MARKED_SEEN = 2;

export default {
  _global: null,
  _unseen: null,
  _notifications: null,
  _count: {
    unseen: 0,
    total: 0,
  },
  _limit: 16,
  _offset: 0,
  _resolvers: [],

  _popup_timeout_trigger: null,
  _popup_seen_ids: [],

  _notifications_interval: null,

  subscribe(cb, vnode = null) {
    if (vnode != null) {
      vnode.$once('hook:beforeDestroy', () => {
        let index = this._resolvers.indexOf(cb);
        this._resolvers.splice(index, 1);
      });
    }
    this._resolvers.push(cb);
    this.send(cb);
  },

  start() {
    // ignore double starts
    if (this._notifications_interval) return;

    // poll instantly
    this.poll();

    // poll in interval
    this.notifications_interval = setInterval(_ => {
      this.poll();
    }, TIMEOUT.FIVE_MINUTES);
  },

  stop() {
    window.clearInterval(this._notifications_interval);
    this._notifications_interval = false;
  },

  load() {
    this._offset = 0;

    const path = `auth/notifications?limit=${this._limit}&offset=${this._offset}`;
    $http
      .get(path)
      .then(({ personal, count }) => {
        this._offset = this._offset + this._limit;
        this._notifications = personal;
        this._count = count;
        this.sendAll();
      })
      .catch(_ => {
        /** supress errors */
      });
  },

  poll() {
    if (!UserService.isActive()) {
      UserService.triggerWhenActive('notification-poll', () => this.poll());
      return;
    }

    $http
      .get('auth/notifications/poll')
      .then(({ glonal, unseen, count }) => {
        this._global = glonal;
        this._unseen = unseen;
        this._count = count;
        this.sendAll();
      })
      .catch(_ => {
        /** supress errors */
      });
  },

  loadMore() {
    const path = `auth/notifications?limit=${this._limit}&offset=${this._offset}`;
    $http
      .get(path)
      .then(({ personal, count }) => {
        this._count = count;
        this._offset = this._offset + this._limit;
        for (const notification_group in personal) {
          if (!personal[notification_group].length) continue;
          this._notifications[notification_group] = [
            ...this._notifications[notification_group],
            ...personal[notification_group],
          ];
        }
        this.sendAll();
      })
      .catch(_ => {
        /** supress errors */
      });
  },

  seen(notification, popup_preview = false) {
    // update status to seen, as when it comes from notification sidebar, instead of popup preview
    notification.status = STATUS_MARKED_SEEN;

    // when popup preview, remove them from unseen list
    if (popup_preview) {
      this.findAndRemoveNotification(notification, { today: this._unseen });
    }

    const path = `auth/notifications/${notification.notification_id}/seen`;
    $http
      .put(path)
      .then(({ count }) => {
        this._count = count;
        this.sendAll();
      })
      .catch(_ => {
        /** supress errors */
      });
  },

  seenAll() {
    for (const notification_group in this._notifications) {
      for (let notification of this._notifications[notification_group]) {
        notification.status = STATUS_MARKED_SEEN;
      }
    }
    this._count.unseen = 0;
    this.sendAll();

    const path = 'auth/notifications/bulk/seen';
    $http.put(path);
  },

  popupSeen(notification) {
    this._popup_seen_ids.push(notification.notification_id);
    window.clearTimeout(this._popup_timeout_trigger);

    // wait thil all ids are gathered from popups
    this._popup_timeout_trigger = setTimeout(() => {
      const path = `auth/notifications/popup`;
      $http.put(path, { notifications: this._popup_seen_ids });
      this._popup_seen_ids = [];
    }, 10000);
  },

  popupSeenAll() {
    window.clearTimeout(this._popup_timeout_trigger);
    this._popup_seen_ids = [];
    this._unseen = [];
    const path = `auth/notifications/bulk/popup`;
    $http.put(path);
  },

  async remove(notification) {
    const path = `auth/notifications/${notification.notification_id}/remove`;
    const { count } = await $http.delete(path);
    this._count = count;

    this.findAndRemoveNotification(notification, this._notifications);
    this.sendAll();
  },

  async removeAll() {
    this._notifications = null;
    this._count = {
      unseen: 0,
      total: 0,
    };
    this.sendAll();

    const path = 'auth/notifications/bulk/remove';
    $http.delete(path);
  },

  findAndRemoveNotification(notification, date_groups) {
    for (const date in date_groups) {
      const index = date_groups[date].indexOf(notification);
      if (index !== -1) {
        date_groups[date].splice(index, 1);
        return true;
      }
    }
    return false;
  },

  send(fn) {
    fn({
      global: this._global,
      unseen: this._unseen,
      notifications: this._notifications,
      count: this._count,
    });
  },

  sendAll() {
    for (const cb of this._resolvers) {
      this.send(cb);
    }
  },
};
