import ContentService from '@master/Services/ContentService';

import Storage from '@libs/Storage';
import Subscriber from '@libs/Subscriber';

class State extends Subscriber {
  #storage = new Storage('feedback');

  constructor() {
    super();

    this.data = {
      opened: this.#storage.get() ?? false,
    };
  }

  toggle() {
    this.data = {
      opened: !this.data.opened,
    };
    this.#storage.set(this.data.opened);
  }
}

class FeedbackService extends ContentService {
  state = new State();

  load(creative_id) {
    if (creative_id != null) {
      super.load(`creatives/${creative_id}/public/feedback/comments`);
    }
  }
}

export default new FeedbackService();
