import { SUBSCRIPTION } from '@master/constants';

export default class GroupSubscription {
  constructor(subscription = null) {
    this.plan_id = subscription?.plan?.plan_id ?? null;
    this.name = subscription?.plan?.name ?? null;
    this.trial = subscription?.trial ?? false;
    this.active = subscription?.active ?? true;
  }

  noPlan() {
    return this.plan_id == null || !this.active;
  }

  canChange() {
    return !this.isUnlimited() && !this.isTrial() && !this.noPlan();
  }

  hasAnalyticsAccess() {
    return !this.isTrial();
  }

  isUnlimited() {
    return this.isEnterprise() || this.isIntegration() || this.isPayAsYouGo();
  }

  isTrial() {
    if (this.noPlan()) return false;
    return this.trial;
  }

  isBasic() {
    if (this.noPlan()) return false;
    return this.plan_id === SUBSCRIPTION.BASIC;
  }

  isEnterprise() {
    if (this.noPlan()) return false;
    return this.plan_id === SUBSCRIPTION.ENTERPRISE;
  }

  isIntegration() {
    if (this.noPlan()) return false;
    return this.plan_id === SUBSCRIPTION.INTEGRATION;
  }

  isPayAsYouGo() {
    if (this.noPlan()) return false;
    return this.plan_id === SUBSCRIPTION.PAYASYOUGO;
  }
}
