<script>
import { STATUS } from '@master/constants';

export default {
  methods: {
    isDraft(status) {
      return status === STATUS.DRAFT;
    },
    isPaused(status) {
      return status === STATUS.PAUSED;
    },
    isLive(status) {
      return status === STATUS.LIVE;
    },
    isWaiting(status) {
      return status === STATUS.PENDING;
    },
    isFinished(status) {
      return [STATUS.FINISHED, STATUS.DEMO_FINISHED].includes(status);
    },
    isDemoFinished(status) {
      return status === STATUS.DEMO_FINISHED;
    },
    isPublished(status) {
      return (
        this.isPaused(status) ||
        this.isLive(status) ||
        this.isWaiting(status) ||
        this.isFinished(status)
      );
    },
    isArchived(status) {
      return status === STATUS.ARCHIVED;
    },
    isDuplicationInProgress(status) {
      return status === STATUS.DUPLICATION_IN_PROGRESS;
    },
    isImportInProgress(status) {
      return status === STATUS.IMPORT_IN_PROGRESS;
    },
    isProcessing(status) {
      return (
        this.isDuplicationInProgress(status) || this.isImportInProgress(status)
      );
    },
  },
};
</script>
