import { DSP } from '@master/constants';

import Subscriber from '@libs/Subscriber';

class DSPService extends Subscriber {
  subscribe(cb, vnode = null) {
    if (this.data === undefined) {
      this.load();
    }
    super.subscribe(cb, vnode);
  }

  load() {
    const path = 'v2/dsp';
    return super.load(path);
  }

  async get(slug) {
    if (!slug) return null;

    if (this.data === undefined) {
      await this.load();
    }

    return Promise.resolve(this.data.find(dsp => dsp.slug === slug));
  }

  hasTag(slug) {
    return (
      slug !== DSP.GOOGLEADS
    ); /** tag download is enabled for everything, except googleads */
  }

  hasZip(slug) {
    return (
      slug === DSP.GOOGLEADS
    ); /** zip download is enabled for googleads only */
  }
}

export default new DSPService();
