/**
 * @module SaveService
 * @description SaveService handles save button loading state and updates timestamp.
 * @example timestamp examples: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/
 */

import moment from 'moment';
import Filters from '@master/Filters';

const base = {
  saving: false,
  timestamp: null,
  unix: null,

  subscribe(cb, vnode = null) {
    this.unsubscribe(vnode, cb);
    this.subscribers.add(cb);
    this.dispatch(cb);
  },

  unsubscribe(vnode, cb) {
    if (vnode != null) {
      vnode.$once('hook:beforeDestroy', () => {
        if (this.subscribers.has(cb)) {
          this.subscribers.delete(cb);
        }
      });
    }
  },

  distribute() {
    for (const cb of this.subscribers) {
      this.dispatch(cb);
    }
  },

  dispatch(cb) {
    cb?.({
      saving: this.saving,
      timestamp: this.timestamp,
    });
  },

  setSaving(saving) {
    this.saving = saving;
    this.distribute();
  },

  startSaving() {
    this.setSaving(true);
  },

  stopSaving() {
    this.setSaving(false);
  },

  setTimestamp(unix) {
    unix = unix ?? moment().unix();
    this.unix = unix;
    this.timestamp = Filters.TimeDifferenceFilter(unix);
    this.distribute();
  },

  getTimestamp() {
    return this.timestamp;
  },

  getUnix() {
    return this.unix;
  },
};

export default {
  creative: Object.assign({}, base, {
    subscribers: new Set(),
  }),

  flight: Object.assign({}, base, {
    subscribers: new Set(),
  }),
};
