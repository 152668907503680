<template lang="pug">
div.py-8.flex.flex-align-center.column-gap-12(@click="$router.push(path)")
  CreativeThumbnail(:asset="{thumbnail_url: creative.splash}")

  section.flex-grow(style="min-width: 0")
    p.fw-500.fs-16.overflow-ellipsis {{ creative.name }}
    p.color-gray-800.fs-14 {{ size }}

  i.nexd-icon-16-arrow-right-small.color-primary(aria-hidden="true")
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Mixins from '@_helpers/Mixins.vue';

import CreativeThumbnail from '@master/UI/Thumbnail/CreativeThumbnail.vue';

export default {
  name: 'ListItem',
  mixins: [CreativeTraits, Mixins],

  components: {
    CreativeThumbnail,
  },

  props: {
    creative: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    size() {
      if (this.isFullscreen()) return 'Fullscreen';
      if (this.isQuantum() && this.creative.quantum == null) return 'Multiple';

      return `${this.creative.width}x${this.creative.height}`;
    },

    view() {
      if (this.isHash()) {
        return 'l';
      }

      // @deprecated by SD-7644 / shortener URL / hash views
      if (this.isList()) {
        return 'list';
      }

      return 'c';
    },

    view_id() {
      if (this.isHash()) {
        return 'hash';
      }

      // @deprecated by SD-7644 / shortener URL / hash views
      if (this.isList()) {
        return 'creative_ids';
      }

      return 'campaign_id';
    },

    path() {
      let path = `/${this.view}/${this.$route.params[this.view_id]}`;

      if (this.$route?.params?.folder_id != null) {
        path += `/f/${this.$route.params.folder_id}`;
      }

      path += `/creative/${this.creative.creative_id}`;

      return {
        path,
        hash: this.creative.quantum != null ? `#${this.creative.quantum}` : '',
      };
    },
  },
};
</script>
