import { confirm, alert } from '@libs/alerts';

export default {
  install: Vue => {
    Vue.mixin({
      beforeCreate() {
        this.$alert = (...args) => {
          return alert(Vue, ...args);
        };

        this.$confirm = (...args) => {
          return confirm(Vue, ...args);
        };
      },
    });
  },
};
